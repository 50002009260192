import { Box, Button, Container, Input, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import swal from "sweetalert";
import axios from "axios";
import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import "./account.css";
import { theme } from "../../theme";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Employee() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [users, setUser] = useState(null);
    const [load, setLoad] = useState(false);
    const [searched, setSearched] = useState("");
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        if (load == false) {
            axios.get(`https://server.hces2.com/auth/getUser`, {}).then((res) => {
                setProfile(res.data.data.username);
            });
            axios
                .get(`https://server.hces2.com/auth/getEmployee`, {})
                .then((res) => {})
                .then((res) => setLoad(true));
            axios
                .get(`https://server.hces2.com/adminhistory/get`, {})
                .then((res) => {
                    if (res.data.data) localStorage.setItem("data", JSON.stringify(res.data.data));
                    setUser(res.data.data);
                    setLoad(true);
                })
                .then((res) => setLoad(true));
        }
    }, [load]);
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const filteredRows = JSON.parse(localStorage.getItem("data")).filter((row) => {
                const a = row?.username.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                const b = Number(row.iduser).toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                return a || b;
            });
            setUser(filteredRows);
        } else {
            setUser(JSON.parse(localStorage.getItem("data")));
        }
    };
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            py: 1
                        }}>
                        <Container maxWidth={false}>
                            <div className="container_set">Lịch sử nhân viên</div>
                            <div className="form_set">
                                <Box sx={{ minWidth: 1000 }}>
                                    {/*<TextField
                                        value={searched}
                                        onChange={(searchVal) => requestSearch(searchVal.target.value)}
                                        placeholder="Tìm kiếm"
                                        sx={{ marginBottom: "5px", paddingRight: "700px" }}
                                    />*/}
                                    {profile == "administrator" ? (
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ padding: "10px" }}>Nhân viên</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Loại</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Người chơi</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Chi tiết</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Số điểm</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Thời gian</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {users &&
                                                    users.map((item) => (
                                                        <>
                                                            <TableRow>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item.username}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item.type}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item.player}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item.detail}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item.money.toLocaleString()}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{formatDate(new Date(item.createdAt))}</TableCell>
                                                            </TableRow>
                                                        </>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    ) : (
                                        <div>Bạn không có quyền truy cập</div>
                                    )}
                                </Box>
                            </div>
                        </Container>
                    </Box>
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}

export default Employee;
