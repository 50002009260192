import Footer from "../../components/Footer/Footer";
import "./home.css";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import "./profile.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import swal from "sweetalert";
function WithDraw() {
    const [profile, setProfile] = useState(null);
    const [bank, setBank] = useState(null);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .get(`https://server.hces2.com/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch(() => {
                localStorage.removeItem("user");
                navigate("/login");
            });
        axios
            .get(`https://server.hces2.com/bank/getBank`, {})
            .then((res) => {
                setBank(res.data.data);
            })
            .catch((err) => setBank(true));
    }, []);
    const onSubmit = (data) => {
        if (data.money > profile.money) {
            setError("money", {
                type: "minLength",
                message: "Số điểm rút vui lòng nhỏ hơn Số điểm hiện có"
            });
            return;
        }
        if (data.money < 50) {
            setError("money", {
                type: "minLength",
                message: "Số điểm rút tối thiểu là 50"
            });
            return;
        }
        if (!bank[0]) {
            swal({
                title: "Bạn chưa thêm tài khoản ngân hàng",
                text: "Vui lòng liên kết thẻ ngân hàng để rút điểm",
                icon: "info",
                buttons: "Liên kết ngay"
            }).then(() => navigate("/addbank"));
        }
        const formData = {
            money: data.money,
            type_payment: "RÚT",
            //detail: data.stk ? data.stk + " - " + data.bank + " - " + data.name : "liên hệ cskh",
            detail: bank ? bank[0].fullname + " - " + bank[0].stk + " - " + bank[0].name_bank : "Chưa có ngân hàng",
            status_payment: "Pending",
            //content: data.detail ? data.detail : "",
            user: profile._id
        };
        axios
            .post(`https://server.hces2.com/payment/withDraw`, formData)
            .then((res) => {
                swal({
                    title: "Thành công",
                    text: "",
                    icon: "success",
                    buttons: "OK"
                }).then(() => navigate("/mine"));
            })
            .catch((err) =>
                setError("money", {
                    type: "minLength",
                    message: "Lỗi giao dịch 404!"
                })
            );
    };
    return (
        <>
            <div className="app1">
                <div className="header_profile">
                    <div className="info_profile">
                        <div style={{width:"20px"}}></div>
                        <div className="avatar_profile">
                            <div className="name_profile"></div>

                            <div
                                style={{
                                    marginLeft: "20px",
                                    fontSize: "1.2rem",
                                    fontWeight: 600,
                                    color: "white"
                                }}>
                                Rút điểm
                            </div>
                        </div>
                        <Link to="/mine" className="icon_setting_header">
                            &times;
                        </Link>
                    </div>
                </div>
                <div className="content_profile">
                    {/*<img
						style={{ width: "40%", marginTop: "20px" }}
						src="https://play-lh.googleusercontent.com/ufwUy4SGVTqCs8fcp6Ajxfpae0bNImN1Rq2cXUjWI7jlmNMCsXgQE5C3yUEzBu5Gadkz"
						alt=""
					/>
					<div style={{color:"red", fontWeight:600}}>Người nhận: usertest2</div>
								<div style={{color:"red",fontWeight:600}}>Vietcombank 1231231231 </div>*/}
                    <form style={{ display: "block", padding: "15px", textAlign: "left" }} onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            {bank == null && !profile ? (
                                <>
                                    {/*<h3 style={{textAlign:"center"}}>Vui lòng thêm ngân hàng</h3>
								<Link to="/addbank" className="btn-submit" style={{textDecoration:"none",textAlign:"center",width:"180px"}}>Thêm ngân hàng</Link>*/}
                                    <p style={{ textAlign: "center" }}>Đang tải ...</p>
                                </>
                            ) : (
                                <>
                                    Số điểm hiện có: {Math.floor(profile?.money).toLocaleString()} đ
                                    <div>
                                        <input className="ipadd" type="number" {...register("money", { required: true })} placeholder="Nhập số điểm cần rút" />
                                    </div>
                                    <div style={{ display: "none" }}>
                                        {/*<textarea className="ipadd" {...register("content", { required: false })} placeholder="Ghi chú" rows="4"></textarea>
										<input
									className="ipadd"
									type="text"
									{...register("stk", { required: true })}
									placeholder="Nhập số tài khoản"
								/>
								<input
									className="ipadd"
									type="text"
									{...register("bank", { required: true })}
									placeholder="Nhập tên ngân hàng"
								/>
								<input
									className="ipadd"
									type="text"
									{...register("name", { required: true })}
									placeholder="Nhập tên chủ tài khoản"
								/>
								
								<select style={{color: "#333"}}
									{...register("detail", { required: true })}
								>
									<option value="">Chọn ngân hàng</option>
									{bank.map((item, index) => (
										<option
											key={index}
											value={item.name_bank + ' - ' + item.fullname + ' - ' + item.stk}
										>
											{"STK " + item.stk + " - " + item.fullname + " - " + item.name_bank}
										</option>
									))}
									</select>*/}
                                    </div>
                                    <button type="submit" className="btn-submit">
                                        Xác nhận
                                    </button>
                                </>
                            )}

                            {errors.money ? <p style={{ color: "red" }}>{errors.money.message}</p> : null}
                        </div>
                    </form>
                </div>

                <Footer profile={profile} />
            </div>
        </>
    );
}
export default WithDraw;
