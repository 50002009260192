import Footer from "../../components/Footer/Footer";
import "./home.css";

import "./addmn.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../img/1.png";
import { useEffect, useState } from "react";
import axios from "axios";
import pending from "../../img/pending.png";
import win from "../../img/win.png";
import lose from "../../img/lose.png";

function HistoryBet() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [profile, setProfile] = useState(null);
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        axios
            .get(`https://server.hces2.com/history/historyus`, {})
            .then((res) => {
                setData(res.data.data);
            })
            .catch((err) => function () {});
        axios
            .get(`https://server.hces2.com/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch(() => {
                localStorage.removeItem("user");
                navigate("/login");
            });
    }, []);
    return (
        <>
            <div className="app1">
                <div className="header_profile">
                    <div className="info_profile">
                        <div style={{width:"20px"}}></div>
                        <div className="avatar_profile">
                            <div className="name_profile"></div>

                            <div
                                style={{
                                    marginLeft: "20px",
                                    fontSize: "1.2rem",
                                    fontWeight: 600,
                                    color: "white"
                                }}>
                                Lịch sử tham gia
                            </div>
                        </div>
                        <Link to="/mine" className="icon_setting_header">
                            &times;
                        </Link>
                    </div>
                </div>
                {data != null ? (
                    <div className="content_profile" style={{ padding: "0 0 95px" }}>
                        {data?.map((item, key) => (
                            <>
                                <div key={key} className="history_payment">
                                    <div style={{ display: "flex" }}>
                                        {item?.status_bet === "Pending" ? (
                                            <>
                                                <img
                                                    src={pending}
                                                    style={{
                                                        width: "40px",
                                                        height: "40px",
                                                        transform: "scale(1.05)",
                                                        backgroundColor: "white"
                                                    }}
                                                />
                                            </>
                                        ) : null}
                                        {item?.status_bet === "Win" ? (
                                            <>
                                                <img
                                                    src={win}
                                                    style={{
                                                        width: "40px",
                                                        height: "40px",
                                                        backgroundColor: "white"
                                                    }}
                                                />
                                            </>
                                        ) : null}
                                        {item?.status_bet === "Lose" ? (
                                            <>
                                                <img
                                                    src={lose}
                                                    style={{
                                                        width: "40px",
                                                        height: "40px",
                                                        backgroundColor: "white"
                                                    }}
                                                />
                                            </>
                                        ) : null}

                                        <div className="type_payment">
                                            {item?.status_bet === "Pending" ? (
                                                <div style={{ color: "orange" }} className="typepayment_detail">
                                                    {item.type_game == 2 && <>{item?.bet == 1 ? "NHẬP" : item?.bet == 2 ? "XUẤT" : ""}</>}
                                                    {item.type_game == 3 && <>{item?.bet == 1 ? "ĐÁNH GIÁ" : item?.bet == 2 ? "THÀNH TÍCH" : ""}</>}
                                                    {item.type_game == 4 && <>{item?.bet == 1 ? "NỘI ĐỊA" : item?.bet == 2 ? "QUỐC TẾ" : ""}</>}
                                                </div>
                                            ) : (
                                                <div className="typepayment_detail">
                                                    {item.type_game == 2 && <>{item?.bet == 1 ? "NHẬP" : item?.bet == 2 ? "XUẤT" : ""}</>}
                                                    {item.type_game == 3 && <>{item?.bet == 1 ? "ĐÁNH GIÁ" : item?.bet == 2 ? "THÀNH TÍCH" : ""}</>}
                                                    {item.type_game == 4 && <>{item?.bet == 1 ? "NỘI ĐỊA" : item?.bet == 2 ? "QUỐC TẾ" : ""}</>}
                                                </div>
                                            )}
                                            <div className="date_time">{formatDate(new Date(item?.createdAt))}</div>
                                            <div className="date_time">{item?.status_bet}</div>
                                        </div>
                                    </div>
                                    {item?.status_bet === "Pending" ? (
                                        <div style={{ color: "orange" }} className="money_pamn">
                                            {item?.money}
                                        </div>
                                    ) : null}
                                    {item?.status_bet === "Win" ? (
                                        <div style={{ color: "green" }} className="money_pamn">
                                            +{item?.money}
                                        </div>
                                    ) : null}
                                    {item?.status_bet === "Lose" ? (
                                        <div style={{ color: "red" }} className="money_pamn">
                                            -{item?.money}
                                        </div>
                                    ) : null}
                                </div>
                                <hr className="line-pay" />
                            </>
                        ))}
                    </div>
                ) : (
                    <></>
                )}

                <Footer profile={profile} />
            </div>
        </>
    );
}
export default HistoryBet;
